export const shuffle = (arr) => {
  return [...arr].sort(() => Math.random() - 0.5);
};

export const currentYear = 2022;

export const colorModes = ["light", "dark"];
export const textModes = ["on", "off"];

export const clickOutside = (el, onClickOutside, validator) => {
  const cb = (e) => {
    if (validator) {
      if (validator(el, e)) {
        onClickOutside();
      }
    } else if (e.target !== el && !el.contains(e.target)) {
      onClickOutside();
    }
  };

  document.addEventListener("click", cb);
  const unregisterExits = registerExits(onClickOutside);

  return () => {
    unregisterExits();
    document.removeEventListener("click", cb);
  };
};

const KEYS = {
  ESCAPE: 27,
};

export const registerExits = (onEscape) => {
  const cb = (e) => {
    if ([KEYS.ESCAPE].includes(e.keyCode)) {
      onEscape();
    }
  };

  document.addEventListener("keyup", cb);
  return () => document.removeEventListener("keyup", cb);
};
