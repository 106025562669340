import React from "react";

import * as styles from "./Mode.module.scss";
import { ModeButton } from "./ModeButton";

const ModeToggles = () => {
  return (
    <div className={styles.toggleWrapper}>
      <ModeButton />
    </div>
  );
};

export { ModeToggles };
