import React, { useContext } from "react";
import { Link } from "gatsby";
import { GMDContext } from "../../context/GMDContext";
import { currentYear } from "../../../utils/common";
import { ModeToggles } from "../mode/ModeToggles";
// import { TextImageMode } from "../mode/TextImageMode";
import * as styles from "./Footer.module.scss";

const Footer = ({ className, location }) => {
  const { studentYear } = useContext(GMDContext);
  const link = location.pathname.split("/")[1];

  const studentPage = link === "students" ? true : false;

  const linkTo =
    link === "students"
      ? studentYear === currentYear
        ? "show"
        : "archive"
      : link;

  return (
    <footer className={styles.footer}>
      <nav aria-labelledby="footermenulabel">
        <h2 id="footermenulabel" className="visually-hidden">
          Footer Menu
        </h2>
        <ul>
          <li>
            <Link to="/">HOME(↑)</Link>
          </li>
          {studentPage && (
            <li className={styles.backlink}>
              <Link to={`/${linkTo}`}>{linkTo}(↑)</Link>
            </li>
          )}
        </ul>
        <ul>
          {/* {gridPage && (
            <li>
              <TextImageMode text={["IMAGES(↑)", "NAMES(↑)"]} />
            </li>
          )} */}
          <li>
            <ModeToggles />
          </li>
        </ul>
      </nav>
    </footer>
  );
};

export { Footer };
