import React from "react";
import smoothscroll from "smoothscroll-polyfill";

import "./src/styles/variables/colors.css";
import "./src/styles/variables/general.css";
import "./src/styles/variables/sizes.css";
import "./src/styles/variables/type.css";
import "./src/styles/global.scss";

import "typeface-libre-franklin";

import Layout from "./src/components/layout/Layout";
import { GMDProvider } from "./src/components/context/GMDContext";
// import { AppContextContainer } from './src/components/context/AppContext';

export const wrapPageElement = ({ element, props }) => {
  return <Layout location={props.location}>{element}</Layout>;
};

export const wrapRootElement = ({ element }) => {
  return <GMDProvider>{element}</GMDProvider>;
};

export const onClientEntry = () => {
  smoothscroll.polyfill();
};
