import React, { useContext } from "react";
import { Spanlink } from "../../common/Spanlink";
import { GMDContext } from "../../context/GMDContext";
import { currentYear } from "../../../utils/common";

import * as styles from "./Header.module.scss";

const Header = ({ className, location }) => {
  const { studentYear } = useContext(GMDContext);

  const link = location.pathname.split("/")[1];

  // compare the current year in utils to the studentyear set in context in the student template
  const linkTo =
    link === "students"
      ? studentYear === currentYear
        ? "archive"
        : "archive"
      : link;

  return (
    <header className={styles.header} aria-label="Header">
      <nav aria-labelledby="mainmenulabel">
        <h2 id="mainmenulabel" className="visually-hidden">
          Main Menu
        </h2>
        <ul>
          <li className={styles.first}>
            <Spanlink
              to="/"
              uppercase
              between
              // hoverStart
            >
              GMD
            </Spanlink>
          </li>
          <li className={styles.last}>
            <Spanlink
              to={`/${linkTo}`}
              uppercase
              between
              // hoverEnd
            >
              {linkTo}
            </Spanlink>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export { Header };
