import React, { useCallback, useMemo, useContext } from "react";

import classNames from "classnames";

import { GMDContext } from "../../context/GMDContext";
import { colorModes } from "../../../utils/common";
import * as styles from "./Mode.module.scss";

const ModeButton = () => {
  const { colorMode, setColorMode } = useContext(GMDContext);

  const currentColor = useMemo(() => {
    return !colorMode ? colorModes[0] : colorMode;
  }, [colorMode]);

  const nextColour = useMemo(() => {
    const index = colorModes.indexOf(currentColor);
    return colorModes[(index + 1) % colorModes.length];
  }, [currentColor]);

  const modeButtonClick = useCallback(() => {
    setColorMode(nextColour);
  }, [nextColour, setColorMode]);

  return (
    <button
      className={classNames(styles.btn, [styles[nextColour]])}
      onClick={modeButtonClick}
      aria-label={`Switch to the ${nextColour} mode`}
    >
      {nextColour}(•)
    </button>
  );
};

export { ModeButton };
