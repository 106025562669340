import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Header } from "../Header";
import { Footer } from "../Footer";
import classNames from "classnames";

import * as styles from "./Layout.module.scss";

const Layout = ({ children, location }) => {
  const [fullLayout, setFullLayout] = useState(false);
  useEffect(() => {
    setFullLayout(location.pathname.length > 1);
  }, [location.pathname.length]);

  const cls = classNames(
    { [styles.main]: fullLayout },
    { [styles.home]: !fullLayout }
  );
  return (
    <>
      {fullLayout && <Header location={location} />}
      <main className={cls}>{children}</main>
      {fullLayout && <Footer location={location} />}
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object,
};

export default Layout;
