import React from "react";
import { Link } from "gatsby";
import classNames from "classnames";

import * as styles from "./Spanlink.module.scss";

const Spanlink = ({
  children,
  to,
  uppercase,
  around,
  between,
  hoverBetween,
  hoverStart,
  hoverEnd,
  onClick,
  rainbow,
}) => {
  const cls = classNames(
    { [styles.uppercase]: uppercase },
    { [styles.around]: around },
    { [styles.between]: between },
    { [styles.hoverBetween]: hoverBetween },
    { [styles.hoverStart]: hoverStart },
    { [styles.hoverEnd]: hoverEnd },
    { [styles.rainbow]: rainbow },
    { [styles.button]: onClick },
    styles.spanlink
  );
  const spanString = [...children];
  return (
    <>
      {onClick ? (
        <button className={cls} onClick={onClick}>
          {spanString.map((character, index) => (
            <span key={index}>{character}</span>
          ))}
        </button>
      ) : (
        <Link to={to} className={cls}>
          {spanString.map((character, index) => (
            <span key={index}>{character}</span>
          ))}
        </Link>
      )}
    </>
  );
};

export { Spanlink };
